<template>
  <div class="EduHome">
    <EduHeader />
    <main class="containerMain">
      <NavInfo />
      <div class="containerMainRight">
        <div class="containerCenter">
          <div class="containerCenter-Title">1.1.1 介绍</div>
          <div class="containerCenter-video">
            <!-- <video
              ref="videoPlayer"
              src="/i/movie.ogg"
              controls="controls"
              class="video-js vjs-default-skin vjs-16-9 vjs-big-play-centered"
              preload="auto"
            ></video> -->
            <video-player
              id="videoPlayer"
              ref="videoPlayer"
              class="vjs-custom-skin"
              :options="playerOptions"
              @fullscreenchange="fullscreenchange"
              @showvideodocument="showVideoDocument"
            />
          </div>
          <div class="containerCenter-Title-1">会计方程，重新排列</div>
          <div class="containerCenter-Font">
            到目前为止，我们已引入会计方程式作为资产= 负债 +
            所有者权益。此方程是一个基本概念，对于理解我们如何记录交易（在模块 2
            中处理）和创建财务报表（在模块 3 中处理）非常重要。<br /><br />另一种看待会计方程的方法，可能有助于理解的目的，是考虑重新排列为所有者权益=资产和负债的等式。<br /><br />这对于理解所有者的权益特别有帮助。从本质上讲，所有者的权益是企业拥有什么和企业欠他人的东西之间的区别。企业清偿所有债务后遗留下来的金额是所有者权益，或属于企业所有者的金额。
          </div>
          <div class="containerCenter-Title-1">典型资产、负债和权益</div>
          <div class="containerCenter-Font">
            在我们继续前进之前，让我们回顾一下我们刚刚介绍的三家公司。以下是您在每家公司资产负债表上可能看到的一些典型资产、负债和权益。
          </div>
          <div class="containerCenter-Ul">
            <ul>
              <li>资产：现金、库存、建筑物、电脑</li>
              <li>负债：应付账款、应付薪金</li>
              <li>股东权益：出资、留存收益</li>
            </ul>
          </div>
          <div class="page-but">向下钻</div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import EduHeader from "@/components/EduHeader";
import NavInfo from "@/components/NavInfo";
// import "video.js/dist/video-js.css";
// import "@/components/VueVideoPlayer/custom-theme.css";
import videoPlayer from "@/components/VueVideoPlayer/player.vue";
export default {
  name: "EduHome",
  data() {
    return {
      activeName: "Teaching",
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.25, 1.5, 2.0], // 播放速度
        autoplay: true, // 如果true,浏览器准备好时开始回放。
        controls: true, // 控制条
        preload: "auto", // 视频预加载
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [],
        poster: "", // 你的封面地址
        width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息
        isShowVideoDocBtn: true, //是否显示视频文稿的按钮,默认是不显示
      },
      isFullscreen: false,
    };
  },
  components: {
    EduHeader,
    NavInfo,
    videoPlayer,
  },
  created() {
    //'http://backoffice.siriux.cygnux.cn/'
    this.setPlay(
      "video/mp4",
      "http://localhost:10001/upload/video/21/7119/8998/511c/efc02a045fd47b75a8.mp4"
    );
  },
  methods: {
    fullscreenchange(e) {
      //alert(this.$refs.videoPlayer.player)
      this.isFullscreen = !this.isFullscreen;
    },
    setPlay(type, url) {
      this.playerOptions.sources = [{ type: type, src: url }];
    },
    showVideoDocument() {
      if (this.isFullscreen) {
        //不显示视频文稿
        alert("不显示视频文稿");
      } else {
        alert("显示视频文稿");
      }
    },
    closePlay() {
      if (
        this.$refs.videoPlayer.player &&
        this.$refs.videoPlayer.player.hasStarted_
      ) {
        this.$refs.videoPlayer.player.pause();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.containerMain {
  position: relative;
  .containerMainRight {
    padding-left: 410px;
    .containerCenter {
      width: 700px;
      margin: 0px auto;
      padding: 75px 0 15px 0;
      .containerCenter-Title {
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 0px;
        color: #060606;

        line-height: 50px;
      }
      .containerCenter-video {
        margin-top: 15px;
        video {
          height: 450px;
          width: 100%;
          background: #000000;
        }
      }
      .containerCenter-Title-1 {
        line-height: 40px;
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #575b67;

        margin-top: 30px;
      }
      .containerCenter-Font {
        margin-top: 30px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #060606;
      }
      .containerCenter-Ul {
        margin-top: 30px;
        li {
          position: relative;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 32px;
          color: #575a63;

          padding-left: 15px;
          &::after {
            display: block;
            width: 5px;
            height: 5px;
            position: absolute;
            left: 0;
            top: 13px;
            content: "";
            background: #575a63;
            border-radius: 50%;
          }
        }
      }
      .page-but {
        margin-top: 30px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #575a63;
      }
    }
  }
}
.EduHome {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}
</style>
